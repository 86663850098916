/*
 * @Author: fan.lion@foxmail.com
 * @Date: 2019-09-16 19:38:10
 * @Last Modified by: yorshka
 * @Last Modified time: 2020-05-25 18:36:07
 *
 * 下载页
 */
import React, { useState, useEffect } from "react"
import classnames from "classnames"
import { useIntl } from "gatsby-plugin-intl"
import QRCode from "qrcode.react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { useSiteMetadata } from "../hooks"
import style from "../styles/download.module.css"
import windowsPng from "../images/download-win@2x.png"
import macPng from "../images/download-mac@2x.png"
import andoridPng from "../images/download-android@2x.png"
import iosPng from "../images/download-ios@2x.png"
import qRCodeIcon from "../images/download-qrcode.png"
import packageInfo from "../../static/packageInfo.json"
import { requestDownloadURL } from "../api/index"

const result =
  typeof window !== "undefined" && navigator.userAgent.toLowerCase()

function DownloadPage() {
  const [interWeiXin, setInterWeixin] = useState(false)
  const [isIOS, setIsIOS] = useState(false)
  const site = useSiteMetadata()
  const intl = useIntl()

  type vendorName = "teampro" | "jointplus" | "yunlu"
  const [appData, setAppData] = useState(
    packageInfo[site.vendor_name as vendorName]
  )
  const { windows, mac, android, ios } = appData

  // 根据接口获取下载链接并替换原始packageInfo
  useEffect(() => {
    requestDownloadURL()
      .then(res => {
        const { data } = res
        const ios = data.find(item => item.platform == "iOS") || {}
        const android = data.find(item => item.platform == "Android") || {}
        const windows = data.find(item => item.platform == "Windows") || {}
        const mac = data.find(item => item.platform == "macOS") || {}

        setAppData({
          windows,
          mac,
          android,
          ios,
        })
      })
      .catch(err => console.error("err", err.message))
  }, [])

  useEffect(() => {
    const interWeiXinFlag =
      result &&
      result.match(/MicroMessenger/i) &&
      result.match(/MicroMessenger/i)[0] === "micromessenger"
    const isIOSFlag = result && result.match(/(iPhone|iPod|iPad);?/i)
    setInterWeixin(interWeiXinFlag)
    setIsIOS(isIOSFlag)
  }, [])

  if (interWeiXin) {
    const locale = intl.locale.replace("-", "_")
    return (
      <div
        className={classnames(
          style.open,
          style.bg,
          isIOS ? style[`ios_${locale}`] : style[`android_${locale}`]
        )}
      >
        {/* 请在手机浏览器打开 */}
      </div>
    )
  }

  const showIosDownload = site.show_ios_download === "true" // 是否显示 iOS 下载链接
  const showIosQrCode = site.support_ios_qrcode === "true" // 是否支持iOS二维码
  const showAndroidQrCode = site.support_android_qrcode === "true" // 是否支持Android二维码

  return (
    <Layout activeNav="download">
      <SEO
        title={`${intl.formatMessage({ id: "下载" })}${site.name}`}
        lang={intl.locale}
      />

      <div className={style.main}>
        <section className={style.section1}>
          <h1>
            {intl.formatMessage({ id: "立即体验" })}
            {site.name}
          </h1>
          <p>
            {intl.formatMessage({
              id:
                "您可以随时通过移动端和桌面端软件，召开或参与高清视讯会议，并同团队进行便捷的远程协作",
            })}
          </p>
        </section>

        <section className={style.section2}>
          <div className={style.section2_row}>
            {windows.version && (
              <div className={style.section2_item}>
                <div className={style.section2_item_left}>
                  <img src={windowsPng} alt="windows os" />
                  <div>{intl.formatMessage({ id: "Windows 版" })}</div>
                </div>

                <div className={style.section2_item_right}>
                  <h1>
                    {site.name} {intl.formatMessage({ id: "Windows 版" })}
                  </h1>
                  <div className={style.version}>
                    {intl.formatMessage({ id: "版本" })} {windows.version}
                  </div>
                  <div className={style.platform}>
                    {intl.formatMessage({ id: "支持" })}{" "}
                    {windows.downloadTips && windows.downloadTips[0]}
                  </div>

                  <a
                    className={style.download_btn}
                    href={windows.downloadLink}
                    role="button"
                    download
                  >
                    {intl.formatMessage({ id: "客户端下载" })}
                  </a>
                </div>
              </div>
            )}

            {mac.version && (
              <div className={style.section2_item}>
                <div className={style.section2_item_left}>
                  <img src={macPng} alt="mac os" />
                  <div>{intl.formatMessage({ id: "macOS 版" })}</div>
                </div>

                <div className={style.section2_item_right}>
                  <h1>
                    {site.name} {intl.formatMessage({ id: "macOS 版" })}
                  </h1>
                  <div className={style.version}>
                    {intl.formatMessage({ id: "版本" })} {mac.version}
                  </div>
                  <div className={style.platform}>
                    {intl.formatMessage({ id: "支持" })}{" "}
                    {mac.downloadTips && mac.downloadTips[0]}
                  </div>

                  <a
                    className={style.download_btn}
                    href={mac.downloadLink}
                    role="button"
                    download
                  >
                    {intl.formatMessage({ id: "客户端下载" })}
                  </a>
                </div>
              </div>
            )}
          </div>

          <div className={style.section2_row}>
            {android.downloadLink && (
              <div className={style.section2_item}>
                <div className={style.section2_item_left}>
                  <img src={andoridPng} alt="android" />
                  <div>{intl.formatMessage({ id: "Android版" })}</div>
                </div>

                <div className={style.section2_item_right}>
                  <h1>
                    {site.name} {intl.formatMessage({ id: "Android 版" })}
                  </h1>
                  <div className={style.version}>
                    {intl.formatMessage({ id: "版本" })} {android.version}
                  </div>
                  <div className={style.platform}>
                    {intl.formatMessage({ id: "支持" })}{" "}
                    {android.downloadTips && android.downloadTips[0]}
                  </div>

                  <div className={style.section2_item_right_foot}>
                    <a
                      className={style.download_btn}
                      href={android.downloadLink}
                      role="button"
                      download
                    >
                      {intl.formatMessage({ id: "APP下载" })}
                    </a>

                    {showAndroidQrCode && (
                      <div className={style.qrcodeWrap}>
                        <img
                          className={style.qrcode_icon}
                          src={qRCodeIcon}
                          alt="QR code"
                        />

                        <img
                          className={style.qrcode_url}
                          src={android.QRcodeImageLink || ""}
                          alt="qrcode"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {ios.version && showIosDownload && (
              <div className={style.section2_item}>
                <div className={style.section2_item_left}>
                  <img src={iosPng} alt="ios" />
                  <div>{intl.formatMessage({ id: "iOS 版" })}</div>
                </div>

                <div className={style.section2_item_right}>
                  <h1>
                    {site.name} {intl.formatMessage({ id: "iOS 版" })}
                  </h1>
                  <div className={style.version}>
                    {intl.formatMessage({ id: "版本" })} {ios.version}
                  </div>
                  <div className={style.platform}>
                    {intl.formatMessage({ id: "支持" })}{" "}
                    {ios.downloadTips && ios.downloadTips[0]}
                  </div>

                  <div className={style.section2_item_right_foot}>
                    <a
                      className={style.download_btn}
                      href={ios.downloadLink}
                      role="button"
                      target="_blank"
                    >
                      {intl.formatMessage({ id: "APP下载" })}
                    </a>

                    {showIosQrCode && (
                      <div className={style.qrcodeWrap}>
                        <img
                          className={style.qrcode_icon}
                          src={qRCodeIcon}
                          alt="QR code"
                        />

                        <img
                          className={style.qrcode_url}
                          src={ios.QRcodeImageLink || ""}
                          alt="qrcode"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>
      </div>

      {/* 背景三角形 */}
      <div className={style.triangle1}></div>
      <div className={style.triangle2}></div>
      <div className={style.triangle3}></div>
    </Layout>
  )
}

export default DownloadPage
